import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "steps"
    }}>{`Steps`}</h1>
    <p>{`Documentation for the Steps component.`}</p>
    <div className="steps">
  <ul>
    <li className="old">
      <span></span>
    </li>
    <li className="active">
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
  </ul>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="steps">
  <ul>
    <li class="old">
      <span></span>
    </li>
    <li class="active">
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
  </ul>
</div>
`}</code></pre>
    <h2 {...{
      "id": "change-color"
    }}>{`Change Color`}</h2>
    <p>{`To change the color of the steps, you can use the `}<inlineCode parentName="p">{`background-color`}</inlineCode>{` property on the `}<inlineCode parentName="p">{`li`}</inlineCode>{` element with `}<inlineCode parentName="p">{`old`}</inlineCode>{` class and `}<inlineCode parentName="p">{`active`}</inlineCode>{` class with linear-gradient.`}</p>
    <style type="text/css">
  {`
.steps.custom-color ul li.old {
  background-color: black;
}
.steps.custom-color ul li.active {
  background: linear-gradient(90deg, black 0%, black 50%, transparent 51%);
}`}
    </style>
    <div className="steps custom-color">
  <ul>
    <li className="old">
      <span></span>
    </li>
    <li className="active">
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
  </ul>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.steps.custom-color ul li.old {
  background-color: black;
}
.steps.custom-color ul li.active {
  background: linear-gradient(90deg, black 0%, black 50%, transparent 51%);
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.steps.custom-color {
  ul {
    li {
      &.old {
        background-color: black;
      }
      &.active {
        background: linear-gradient(
          90deg,
          black 0%,
          black 50%,
          transparent 51%
        );
      }
    }
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      